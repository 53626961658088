@font-face {
    font-family: 'Gotham';
    src: url(../fonts/Gotham.otf);
}


$color-main: #fefefe;
$color-secondary: #030c25;
$color-dark: #0a4367;
$color-light: #01afed;
body {
    font-family: 'Gotham';
    width: 100%;
    height: 100vh;
    background-color: $color-main;
    margin: 0;
}
header {
    display: flex;
    position: fixed;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: $color-secondary;
    transition: 1s cubic-bezier(.17,.84,.44,1) all;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    border-bottom: 2px solid #fff;
    z-index: 999;
    &.loaded {
        height: 10vh;
        .logo-body {
            transform: scale(0.3);
            margin-top: 0vh;
        }
    }
}
.logo-body {
    width: fit-content;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: 1s cubic-bezier(.17,.84,.44,1) all;
    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 280px;
        height: auto;
        cursor: default;
        user-select: none;
        background: transparent;
    }
}

main {
    min-height: 150vh;
    display: flex;
    justify-content: center;
    padding-top: 10vh; 
    position: relative;
    &:before {
        content: '';
        background-image: url(../../../public/images/logo.png);
        background-size: 40%;
        background-repeat: no-repeat;
        background-position: bottom right;
        background-attachment: fixed;
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0.2;
    }
       
}
.container {
    width: 100%;
    padding: 4vw 8vw;
    display: flex;
    justify-content: center;
}
.leader-board {
    width: 60%;
    ul {
        width: 100%;
        list-style: none;
        padding: 0;
        li {
            display: flex;
            align-items: center;
            width: 100%;
            margin: 1.5vh 0;
            padding-bottom: .5vh;
            position: relative;
            &:before {
                content: '';
                position: absolute;
                width: 100%;
                height: 2px;
                display: flex;
                bottom: -4px;
                right: -2%;
                background: linear-gradient(90deg, $color-secondary 0%, $color-light 100%); 
            }
            div:nth-child(1){
                width: 20%;
                display: flex;
                justify-content: center;
            }
            div:nth-child(2){
                width: 80%;
                display: flex;
                justify-content: space-between;
                padding: 4px;
                border-radius: 8px;
            }
            .w-bg:not(:empty) {
                width: fit-content;
                display: flex;
                background: $color-secondary;
                color: $color-main;
                align-items: center;
                justify-content: center;
                border: 1px solid $color-light;
                font-size: 22px;
                padding: 4px 12px;
                border-radius: 6px;
            }
            .username {
                display: flex;
                align-items: center;
                font-size: 22px;
                color: $color-secondary;
            }
            &.rank-1 div:nth-child(2) {
                border: 2px solid $color-light;
                background: $color-secondary;
                .username {
                    color: $color-main;
                }
            }
            &.rank-2 div:nth-child(2), &.rank-3 div:nth-child(2) {
                border: 2px solid $color-secondary;
                background: $color-light;
                .username {
                    color: $color-main;
                }
            }
            input {
                width: 80%;
                display: flex;
                justify-content: space-between;
                border-radius: 8px;
                padding: 4px 12px;
                font-size: 22px;
                border: 2px solid #000;
            }
        }
    }
}